var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('iframe',{staticStyle:{"display":"none"},attrs:{"id":"downloadFrame"}}),(_vm.currentView === null)?_c('div'):_c('div',{staticClass:"tw-p-4"},[(_vm.currentView && _vm.currentView.activeViewUpdateId)?_c('div',{staticClass:"tw-flex tw-w-full tw-justify-end tw-items-end tw-gap-4"}):_vm._e(),_c('div',{staticClass:"tw-rounded-lg tw-bg-white tw-shadow tw-mt-3"},[_c('ui-data-table',{staticStyle:{"font-family":"'Helvetica', system-ui"},attrs:{"headers":_vm.headers,"items":_vm.collections,"isLoading":!!_vm.isLoading,"resizeable-columns":"","no-data-message":"There are no NFTS to display.","fixed-table":"","striped":"","shaded-header":"","tbodyClassName":"tw-divide-double tw-divide-gray-300 tw-divide-y","className":"tw-rounded-md","expandable-rows":""},on:{"row-clicked":_vm.selectCollection},scopedSlots:_vm._u([{key:"td-collection_name",fn:function(ref){
var line = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-flex tw-gap-2 tw-items-center"},[(line.collection_logo)?_c('img',{staticClass:"tw-w-10 tw-rounded-full",attrs:{"src":line.collection_logo,"alt":"Collection logo"}}):_vm._e(),_c('div',{staticClass:"tw-flex tw-flex-col tw-w-3/4"},[_c('ui-truncate-text',{attrs:{"copyable":false,"value":line.collection_name,"position":"right"}})],1)])])]}},{key:"td-quantity",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-text-neutral-400 tw-text-right"},[_vm._v(_vm._s(line.quantity ? line.quantity : 0))])])]}},{key:"td-cost_basis_acquired",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-text-neutral-400 tw-flex tw-flex-col tw-items-end"},[_vm._v(" $"+_vm._s(_vm.numFormat(line.cost_basis_acquired, { decimalPlaces: 3 }))+" ")])])]}},{key:"td-cost_basis_disposed",fn:function(ref){
var line = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-text-neutral-400 tw-my-4 tw-pl-3 tw-text-right"},[_vm._v(" $"+_vm._s(_vm.numFormat(line.cost_basis_disposed, { decimalPlaces: 3 }))+" ")])]}},{key:"td-gain_loss",fn:function(ref){
var line = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-text-neutral-400 tw-my-4 tw-pl-3 tw-text-right"},[_vm._v(" $"+_vm._s(_vm.numFormat(line.gain_loss, { decimalPlaces: 3 }))+" ")])]}},{key:"td-cost_basis",fn:function(ref){
var line = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-text-neutral-400 tw-my-4 tw-pl-3 tw-text-right"},[_vm._v(" $"+_vm._s(_vm.numFormat(line.cost_basis, { decimalPlaces: 3 }))+" ")])]}},{key:"td-fmv",fn:function(ref){
var line = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-text-neutral-400 tw-my-4 tw-pl-3 tw-text-right"},[_vm._v(" $"+_vm._s(_vm.numFormat(line.fmv, { decimalPlaces: 3 }))+" ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }