

































































import axios from 'axios';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { baConfig } from '../../../config';
import { ApiSvcCreateInventoryViewRequest, ApiSvcInventoryView, InventoryApi } from '../../../generated/api-svc';
import InventoryViewUpdates from '../..//components/inventory/details/InventoryViewUpdates.vue';
import NFTViewDashboard from '../..//components/nfts/NFTViewDashboard.vue';
import UiButton from '../..//components/ui/UiButton.vue';
import { BaseVue } from '../../BaseVue';
import NFTViewDetail from './NFTViewDetail.vue';

@Component({
  components: {
    UiButton,
    InventoryViewUpdates,
    NFTViewDashboard,
    NFTViewDetail,
  },
})
export default class NFTViewDetails extends BaseVue {
  @Prop({ default: null })
  public readonly inventoryViewId!: string | null;

  @Prop({ default: '' })
  public readonly runId!: string;

  public view: ApiSvcInventoryView | null = null;

  public isLoading = false;
  public isLoadingCollections = false;
  public isUpdatingView = false;
  public actionsDefaultFilter?: { [id: string]: Array<string | number> } | null = null;
  public actionsDefaultAsOf = '';
  public collections: any[] = [];

  public selectedView = 'dashboard';

  public selectedCollection = '';

  public nftSvcUrl = process.env.VUE_APP_API_2_SVC_URL;

  public getTabClass(id: string) {
    if (id === this.selectedView) {
      return 'tw-border-primary-300 tw-text-gray-700 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
    } else {
      return 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
    }
  }

  @Watch('runId')
  async watchInventoryViewId() {
    this.collections = [];
    this.selectedView = 'dashboard';
    await this.loadInventoryView();
    await this.loadCollections();
  }

  async mounted() {
    await this.loadInventoryView();
    await this.loadCollections();
  }

  public async viewActions(options: { status: string; asOf: string }) {
    if (options.status) {
      this.actionsDefaultFilter = { status: [options.status] };
    } else {
      this.actionsDefaultFilter = null;
    }
    this.actionsDefaultAsOf = options.asOf;
    this.selectedView = 'actions';
    await this.$nextTick();
    this.actionsDefaultFilter = null;
    this.actionsDefaultAsOf = '';
  }

  private async loadInventoryView() {
    this.isLoading = true;
    try {
      if (this.inventoryViewId) {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const resp = await svc.getView(this.orgId, this.inventoryViewId, {
          withCredentials: true,
        });
        if (resp.status === 200) {
          this.view = resp.data.item;
          if (this.view.lastUpdateRunSEC) {
            this.selectedView = 'dashboard';
          } else {
            this.selectedView = 'updates';
          }
        }
      }
    } catch (e) {
    } finally {
      this.isLoading = false;
    }
  }

  public refreshViews() {
    this.$emit('refresh-views');
  }

  public async updateInventoryView() {
    if (this.inventoryViewId === null) {
      return;
    }

    this.isUpdatingView = true;

    try {
      const orgId = this.$store.state.currentOrg.id;
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await svc.triggerViewUpdate(orgId, this.inventoryViewId, {
        withCredentials: true,
      });
    } catch (e) {
      console.log('problem updating view', e);
    } finally {
      this.isUpdatingView = false;
    }
  }

  public selectCollection(collection: string) {
    this.selectedCollection = collection;
    this.selectedView = 'detail';
  }

  public async loadCollections() {
    this.isLoadingCollections = true;
    this.$set(
      this,
      'collections',
      (
        (
          await axios.get(`${this.nftSvcUrl}/nfts/${this.runId}/nft`, {
            withCredentials: true,
          })
        )?.data ?? []
      ).filter((c: any) => c.collection_name && c.contract_address)
    );

    if (this.collections?.length > 0) this.selectedCollection = this.collections[0].contract_address ?? '';
    this.isLoadingCollections = false;
  }
}
