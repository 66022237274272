




















































































































import axios from 'axios';
import { i } from 'mathjs';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
import ReportHeader from '@/components/inventory/ReportHeader.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';
import numberFormatter from '@/utils/numberUtils';

import { baConfig } from '../../../config';
import {
  ApiSvcInventoryCounts,
  ApiSvcInventoryShortBalanceLine,
  ApiSvcInventoryView,
  InventoryApi,
} from '../../../generated/api-svc';
import UiTruncateText from '../ui/UiTruncateText.vue';

@Component({
  components: {
    UiDatePicker,
    CreateInventoryView,
    UiButton,
    WalletListNew,
    UiDataTable,
    ReportHeader,
    UiSelect2,
    UiTruncateText,
  },
})
export default class NFTViewDetail extends BaseVue {
  @Prop({ default: null })
  public readonly currentView!: ApiSvcInventoryView | null;

  @Prop({ default: () => [] })
  public readonly collections?: any[];

  @Prop({ default: '' })
  public readonly runId!: string;

  public lines: ApiSvcInventoryShortBalanceLine[] = [];
  public counts: ApiSvcInventoryCounts | null = null;
  public asOf = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');

  public nftSvcUrl = process.env.VUE_APP_API_2_SVC_URL;

  public numFormat = numberFormatter.format;

  public tokens: any[] = [];

  public isLoading = false;

  public attributeHeaders: any[] = [];

  public expandedRowId = '';

  @Prop({ required: true })
  public selectedCollection!: string;

  public get headers() {
    return [
      {
        id: 'token',
        label: 'token',
        defaultVisibility: true,
      },
      {
        id: 'quantity',
        label: 'Qty',
        defaultVisibility: true,
        defaultWidth: '100px',
        textAlignment: 'right',
      },
      {
        id: 'cost_basis_acquired',
        label: 'Cost Basis Acquired',
        defaultVisibility: true,
        textAlignment: 'right',
      },
      {
        id: 'cost_basis_disposed',
        label: 'Cost Basis Disposed',
        defaultVisibility: true,
        textAlignment: 'right',
      },
      {
        id: 'cost_basis',
        label: 'Cost Basis',
        defaultVisibility: true,
        textAlignment: 'right',
      },
      {
        id: 'attributes',
        label: 'Attributes',
        defaultVisibility: false,
        textAlignment: 'right',
      },
    ];
  }

  public viewActions(options: { status: string; asOf: string }) {
    this.$emit('viewActions', options);
  }

  public loadData() {
    this.loadTokens();
  }

  public async loadTokens() {
    if (!this.selectedCollection) {
      return;
    }
    this.isLoading = true;
    this.tokens =
      (
        await axios.get(`${this.nftSvcUrl}/nfts/${this.runId}/collections/${this.selectedCollection}`, {
          withCredentials: true,
        })
      )?.data ?? [];
    this.attributeHeaders = this.parseAttributeHeaders(this.tokens?.[0]?.tokenData?.attributes);
    this.tokens.forEach((x) => (x.id = x.token));
    this.isLoading = false;
  }

  public selectCollection(val: string) {
    this.$emit('selectCollection', val);
  }

  public parseAttributeHeaders(attrs: any[]) {
    if (!attrs?.length) {
      return [];
    }
    return Object.keys(attrs?.[0]);
  }

  public rowClicked(event: any) {
    if (this.expandedRowId === event.item.id) {
      this.expandedRowId = '';
    } else {
      this.expandedRowId = event.item.id;
    }
  }

  @Watch('selectedCollection')
  onSelectedCollectionChange() {
    this.loadData();
  }

  async mounted() {
    this.loadData();
  }
}
