var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('iframe',{staticStyle:{"display":"none"},attrs:{"id":"downloadFrame"}}),(_vm.currentView === null)?_c('div'):_c('div',{staticClass:"tw-p-4"},[(_vm.currentView && _vm.currentView.activeViewUpdateId)?_c('div',{staticClass:"tw-flex tw-justify-between tw-items-end tw-gap-4"},[_c('div',{staticClass:"tw-flex tw-items-center",staticStyle:{"max-width":"30%"}},[_c('ui-select2',{staticClass:"tw-flex-1 tw-mr-2",staticStyle:{"min-width":"250px"},attrs:{"items":_vm.collections,"value":_vm.selectedCollection,"keyField":"contract_address","labelField":"collection_name","searchable":"","keyOnly":""},on:{"input":_vm.selectCollection}})],1)]):_vm._e(),_c('div',{staticClass:"tw-rounded-lg tw-bg-white tw-shadow tw-mt-3"},[_c('ui-data-table',{staticStyle:{"font-family":"'Helvetica', system-ui"},attrs:{"headers":_vm.headers,"items":_vm.tokens,"isLoading":!!_vm.isLoading,"resizeable-columns":"","no-data-message":"There are no NFTS to display.","fixed-table":"","striped":"","shaded-header":"","tbodyClassName":"tw-divide-double tw-divide-gray-300 tw-divide-y","className":"tw-rounded-md","expandable-rows":"","expanded-row-id":_vm.expandedRowId},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"td-token",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-gap-2 tw-items-center"},[_c('div',{staticClass:"hover:tw-bg-gray-200 tw-py-1 tw-px-2 tw-rounded-md"},[_c('fa',{class:_vm.expandedRowId === line.id ? '' : 'fa-rotate-270',attrs:{"icon":"fa-regular fa-angle-down"}})],1),_c('img',{staticClass:"tw-w-10 tw-rounded-full",attrs:{"src":line.tokenData.imageUrl,"alt":"Collection logo"}}),_c('div',{staticClass:"tw-flex tw-flex-col tw-w-3/4"},[_c('ui-truncate-text',{attrs:{"copyable":false,"value":line.tokenData.assetName,"position":"right"}})],1)])])]}},{key:"td-quantity",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-text-neutral-400 tw-text-right"},[_vm._v(" "+_vm._s(line.tokenData.quantity ? line.tokenData.quantity : 0)+" ")])])]}},{key:"td-cost_basis_acquired",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-items-center tw-text-neutral-400 tw-justify-end"},[_vm._v(" $"+_vm._s(_vm.numFormat(line.tokenData.cost_basis_acquired, { decimalPlaces: 3 }))+" ")])])]}},{key:"td-cost_basis_disposed",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-text-neutral-400 tw-items-end"},[_vm._v(" $"+_vm._s(_vm.numFormat(line.tokenData.cost_basis_disposed, { decimalPlaces: 3 }))+" ")])])]}},{key:"td-cost_basis",fn:function(ref){
var line = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-text-right"},[_vm._v(" $"+_vm._s(_vm.numFormat(line.tokenData.cost_basis, { decimalPlaces: 3 }))+" ")])]}},{key:"expanded-row",fn:function(ref){
var line = ref.item;
return [(!_vm.attributeHeaders || !_vm.attributeHeaders.length || !line.tokenData || !line.tokenData.attributes)?_c('div',{staticClass:"tw-flex tw-justify-center tw-p-6 tw-items-cente tw-font-medium"},[_vm._v(" There are no attributes to display. ")]):_c('div',{staticClass:"tw-text-neutral-400 tw-rounded-md tw-border"},[_c('table',{staticClass:"expand-table"},[_c('thead',{staticClass:"tw-border-b tw-rounded-md"},[_c('tr',{staticClass:"tw-rounded-md"},_vm._l((_vm.attributeHeaders),function(header){return _c('th',{key:header,staticClass:"tw-rounded-md tw-text-gray-900 tw-font-medium"},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((line.tokenData.attributes),function(attr,index){return _c('tr',{key:index},_vm._l((_vm.attributeHeaders),function(header,hIndex){return _c('td',{key:hIndex},[_vm._v(" "+_vm._s(attr[header])+" ")])}),0)}),0)])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }