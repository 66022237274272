




































import { resolve } from 'path';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import FirstTimeView from '@/components/inventory/FirstTime/FirstTimeView.vue';
import NFTViewDetails from '@/components/nfts/NFTViewDetails.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiFormLabel from '@/components/ui/UiFormLabel.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';

import { baConfig } from '../../config';
import { ApiSvcInventoryViewDTO, ApiSvcJobStatus, InventoryApi } from '../../generated/api-svc';
@Component({
  components: {
    NFTViewDetails,
    UiButton,
    WalletListNew,
    FirstTimeView,
    UiSelect2,
    UiFormLabel,
  },
})
export default class NFTDashboard extends BaseVue {
  public views: ApiSvcInventoryViewDTO[] = [];
  public isCreatingView = false;
  public isLoadingRunId = false;
  public isLoadingViews = false;
  public selectedViewId: string | null = null;
  public isPaywallVisible = false;
  public showFeedback = true;
  public collections: any[] = [];
  public latestRunId = '';

  public get isLoading() {
    return this.isLoadingViews || this.isLoadingRunId;
  }

  public onCreateView() {
    if (this.views.length === 0 || this.checkFeatureFlag('inventory-create-multiple-views')) {
      this.isCreatingView = true;
    } else {
      this.isPaywallVisible = true;
    }
  }

  public getTabClass(view: string) {
    if (view === this.selectedViewId) {
      return 'tw-bg-neutral-500 tw-text-neutral-100 tw-px-4 tw-py-4 tw-font-medium tw-text-sm';
    } else {
      return 'tw-text-gray-500 hover:tw-text-gray-700 tw-px-4 tw-py-4 tw-font-medium tw-text-sm';
    }
  }

  public async loadInventoryViews() {
    this.isLoadingViews = true;
    try {
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const orgId = this.$store.state.currentOrg.id;
      const views = await svc.getViews(orgId, undefined, { withCredentials: true });
      if (views.status === 200) {
        this.$set(this, 'views', views.data.items);
        this.selectedViewId = this.views[0].id;
      }
    } finally {
      this.isLoadingViews = false;
    }
  }

  public async loadUpdates() {
    if (this.selectedViewId) {
      this.isLoadingRunId = true;
      try {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const orgId = this.$store.state.currentOrg.id;
        const resp = await svc.getViewUpdates(orgId, this.selectedViewId, { withCredentials: true });
        if (resp.status === 200) {
          this.latestRunId = resp.data.items?.find((x) => x.status === ApiSvcJobStatus.Complete)?.gainLossRunId ?? '';
        }
      } finally {
        this.isLoadingRunId = false;
      }
    }
  }

  async mounted() {
    await this.loadInventoryViews();
    await this.loadUpdates();
  }

  @Watch('$store.state.currentOrg.id')
  async orgIdUpdated() {
    await this.loadInventoryViews();
    await this.loadUpdates();
  }
}
