


















































































import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
import ReportHeader from '@/components/inventory/ReportHeader.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiTruncateText from '@/components/ui/UiTruncateText.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';
import numberFormatter from '@/utils/numberUtils';

import { baConfig } from '../../../config';
import {
  ApiSvcInventoryCounts,
  ApiSvcInventoryShortBalanceLine,
  ApiSvcInventoryView,
  InventoryApi,
} from '../../../generated/api-svc';

@Component({
  components: {
    UiDatePicker,
    CreateInventoryView,
    UiButton,
    WalletListNew,
    UiDataTable,
    ReportHeader,
    UiTruncateText,
  },
})
export default class NFTViewDashboard extends BaseVue {
  @Prop({ default: null })
  public readonly currentView!: ApiSvcInventoryView | null;

  @Prop({ default: () => [] })
  public readonly collections!: any[];

  public asOf = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');

  public numFormat = numberFormatter.format;

  @Prop({ default: false })
  public isLoading!: boolean;

  public get headers() {
    return [
      {
        id: 'collection_name',
        label: 'Collection',
        defaultVisibility: true,
        defaultWidth: '300px',
      },
      {
        id: 'quantity',
        label: 'Qty',
        defaultVisibility: true,
        defaultWidth: '100px',
        textAlignment: 'right',
      },
      {
        id: 'cost_basis_acquired',
        label: 'Total Spent',
        defaultVisibility: true,
        textAlignment: 'right',
      },
      {
        id: 'fmv',
        label: 'Sales Proceeds',
        defaultVisibility: true,
        textAlignment: 'right',
      },
      {
        id: 'cost_basis_disposed',
        label: 'Cost Basis Disposed',
        defaultVisibility: true,
        textAlignment: 'right',
      },
      {
        id: 'gain_loss',
        label: 'Gain / Loss on Sales',
        defaultVisibility: true,
        textAlignment: 'right',
      },
      {
        id: 'cost_basis',
        label: 'Cost Basis',
        defaultVisibility: true,
        textAlignment: 'right',
      },
    ];
  }

  public viewActions(options: { status: string; asOf: string }) {
    this.$emit('viewActions', options);
  }

  public submittedAsOf = '';

  public loadData() {
    this.$emit('loadData');
  }

  public selectCollection({ item, row }: { item: any; row: HTMLElement }) {
    console.log('selectCollection', item);
    this.$emit('selectCollection', item.contract_address);
  }

  async mounted() {
    this.loadData();
  }
}
